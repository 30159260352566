// NavBar.js
// Author: Site Crafted

import React, { useState, useEffect } from 'react';
import './NavBar.css';

const NavBar = () => {
  // State to manage whether the mobile menu is active
  const [menuActive, setMenuActive] = useState(false);

  // Toggles the mobile menu open or closed
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  // Google Analytics Page View Tracking
  useEffect(() => {
    if (typeof window.gtag === 'function') {
      window.gtag('event', 'page_view', {
        'page_path': window.location.pathname,
        'page_title': document.title
      });
    }
  }, []);

  return (
    <nav className="navbar" role="navigation" aria-label="Main Navigation">
      {/* Navbar Content Container */}
      <div className="navbar-content">
        {/* Navbar Menu */}
        <ul className={`navbar-menu ${menuActive ? 'active' : ''}`} id="navbar-menu">
          <li className="navbar-item">
            <a href="/" title="Home Page">Home</a>
          </li>
          <li className="navbar-item">
            <a href="/pricelist" title="View Our Services">Services</a>
          </li>
          <li className="navbar-item">
            <a href="/gallery" title="View Our Gallery">Gallery</a>
          </li>
          <li className="navbar-item">
            <a href="/contact-me" title="Contact Us">Contact</a>
          </li>
          <li className="navbar-item">
            <a href="/policy" title="View Our Policy">Policy</a>
          </li>
          <li className="navbar-item">
            <a href="/giftcards" title="Gift Cards">Gift Cards</a>
        </li>
          <li className="navbar-item book-now">
            <a href="/book" title="Book Your Appointment">Book Now</a>
          </li>
        </ul>

        {/* Hamburger Icon for Mobile Menu with Animation */}
        <div
          className="hamburger"
          onClick={toggleMenu}
          aria-label="Toggle navigation"
          aria-expanded={menuActive}
          aria-controls="navbar-menu"
          tabIndex="0"
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleMenu();
            }
          }}
        >
          <div className={`line ${menuActive ? 'line1-active' : ''}`}></div>
          <div className={`line ${menuActive ? 'line2-active' : ''}`}></div>
          <div className={`line ${menuActive ? 'line3-active' : ''}`}></div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;