// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global.css */
/* Author: Site Crafted */

:root {
    /* Colors */
    --primary-color: #FF69B4; /* Highlight color for interactions */
    --secondary-color: #ffff; /* White background color */
    --text-color: #333; /* General text color */
    --button-bg-color: #ffffff; /* Button background color */
    --button-hover-color: #ff85c0; /* Lighter shade for button hover effect */

    /* Fonts */
    --font-family: 'Poppins', sans-serif; /* Primary font family */
    --font-weight-light: 300;
    --font-weight-bold: 600;

    /* Font Sizes */
    --font-size-large: 3rem;
    --font-size-medium: 1.5rem;
    --font-size-small: 1rem;
    --font-size-navbar: 1.2rem; /* Specific size for navbar items */

    /* Spacing */
    --space-small: 8px;
    --space-medium: 16px;
    --space-large: 24px;
    --space-extra-large: 32px;
    --space-xxl: 64px;
    --navbar-padding: 15px; /* Consistent padding for navbar elements */
    --navbar-margin: 15px; /* Space between navbar items */

    /* Other */
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA,eAAe;AACf,yBAAyB;;AAEzB;IACI,WAAW;IACX,wBAAwB,EAAE,qCAAqC;IAC/D,wBAAwB,EAAE,2BAA2B;IACrD,kBAAkB,EAAE,uBAAuB;IAC3C,0BAA0B,EAAE,4BAA4B;IACxD,6BAA6B,EAAE,0CAA0C;;IAEzE,UAAU;IACV,oCAAoC,EAAE,wBAAwB;IAC9D,wBAAwB;IACxB,uBAAuB;;IAEvB,eAAe;IACf,uBAAuB;IACvB,0BAA0B;IAC1B,uBAAuB;IACvB,0BAA0B,EAAE,mCAAmC;;IAE/D,YAAY;IACZ,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,yBAAyB;IACzB,iBAAiB;IACjB,sBAAsB,EAAE,2CAA2C;IACnE,qBAAqB,EAAE,+BAA+B;;IAEtD,UAAU;AACd","sourcesContent":["/* global.css */\n/* Author: Site Crafted */\n\n:root {\n    /* Colors */\n    --primary-color: #FF69B4; /* Highlight color for interactions */\n    --secondary-color: #ffff; /* White background color */\n    --text-color: #333; /* General text color */\n    --button-bg-color: #ffffff; /* Button background color */\n    --button-hover-color: #ff85c0; /* Lighter shade for button hover effect */\n\n    /* Fonts */\n    --font-family: 'Poppins', sans-serif; /* Primary font family */\n    --font-weight-light: 300;\n    --font-weight-bold: 600;\n\n    /* Font Sizes */\n    --font-size-large: 3rem;\n    --font-size-medium: 1.5rem;\n    --font-size-small: 1rem;\n    --font-size-navbar: 1.2rem; /* Specific size for navbar items */\n\n    /* Spacing */\n    --space-small: 8px;\n    --space-medium: 16px;\n    --space-large: 24px;\n    --space-extra-large: 32px;\n    --space-xxl: 64px;\n    --navbar-padding: 15px; /* Consistent padding for navbar elements */\n    --navbar-margin: 15px; /* Space between navbar items */\n\n    /* Other */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
