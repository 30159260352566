// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
/* Author: Site Crafted */



body {
  margin: 0;
  font-family: var(--font-family);
  font-weight: var(--font-weight-light);
  overflow-x: hidden; /* Prevent horizontal scrolling */
  color: var(--text-color);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #FFC0CB, #E6E6FA, #FFE5B4, #E6E6FA, #FFC0CB);
  background-size: cover;
  width: 100%;
  text-align: center;
  padding: var(--space-large);
  
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .App {
    width: 100vw; /* Fix the width to the viewport width */
    overflow-x: hidden; /* Prevent any horizontal scrolling */

    padding: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ,yBAAyB;;;;AAIzB;EACE,SAAS;EACT,+BAA+B;EAC/B,qCAAqC;EACrC,kBAAkB,EAAE,iCAAiC;EACrD,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,gFAAgF;EAChF,sBAAsB;EACtB,WAAW;EACX,kBAAkB;EAClB,2BAA2B;;AAE7B;;AAEA,mCAAmC;AACnC;EACE;IACE,YAAY,EAAE,wCAAwC;IACtD,kBAAkB,EAAE,qCAAqC;;IAEzD,UAAU;EACZ;AACF","sourcesContent":["/* App.css */\n/* Author: Site Crafted */\n\n\n\nbody {\n  margin: 0;\n  font-family: var(--font-family);\n  font-weight: var(--font-weight-light);\n  overflow-x: hidden; /* Prevent horizontal scrolling */\n  color: var(--text-color);\n}\n\n.App {\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  background: linear-gradient(135deg, #FFC0CB, #E6E6FA, #FFE5B4, #E6E6FA, #FFC0CB);\n  background-size: cover;\n  width: 100%;\n  text-align: center;\n  padding: var(--space-large);\n  \n}\n\n/* Media Query for Mobile Devices */\n@media (max-width: 768px) {\n  .App {\n    width: 100vw; /* Fix the width to the viewport width */\n    overflow-x: hidden; /* Prevent any horizontal scrolling */\n\n    padding: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
